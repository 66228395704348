<template>
  <div style="background: #f6f6f6;color: #000;">
    <van-nav-bar title="报价列表" left-arrow placeholder :fixed="true" :z-index="999" @click-left="back">
    </van-nav-bar>
    <div class="nav-bar">
      <van-search v-model="key" show-action placeholder="请输入搜索关键词" @search="onSearch">
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <div class="time" @click="timeshow = true">{{ date }}</div>
    </div>
    <van-list v-model="loading" :finished="finished" :immediate-check="false" finished-text="没有更多了" @load="onLoads" :offset="10">
      <div class="mian" v-for="(item,idx) in list" :key="idx" @click="tourl(item)">
        <div class="zuolit">
          <van-row class="xmdiv">
            <van-col span="12" class="flx">
              <div class="carNo">{{item.carNo}}</div>
            </van-col>
            <van-col span="12" class="ris">
              <span v-if="item.accept == 0"></span>
              <span v-else style="color: #14c969;">已接受报价</span>
            </van-col>
          </van-row>
          <van-row class="xmdiv">
            <van-col span="24">车架号：{{item.vin}}</van-col>
          </van-row>
          <van-row class="xmdiv" style="color: #999;">
            <van-col span="12">车型：{{item.carModel}}</van-col>
            <van-col span="12">{{item.createdDate}}</van-col>
          </van-row>
        </div>
        <div class="sc" @click.stop="dlt(item,idx)">
          <van-icon size="20" color="#999" name="delete-o" />
        </div>
      </div>
    </van-list>
    <van-empty v-if="list.length < 1" description="暂无数据~" />
    <div style="height: 60px;"></div>
    <div class="bot">
      <van-button round type="danger" @click="tourls()">新建报价单</van-button>

    </div>
    <van-popup v-model="timeshow" position="bottom" :style="{ height: '45%' }">
      <van-datetime-picker @cancel="timeshow =false" @confirm="settiem" v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" :max-date="maxDate" :formatter="formatter" />

    </van-popup>
  </div>
</template>
  
<script>
import { getquote, postcbbpay, dltquote, fixpay } from "@/api/check";
import userModel from "@/api/user";
import moment from "moment";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  data() {
    return {
      // , "余额充值"
      list: [], //
      carlist: [],
      gid: this.$route.query.gid,
      phoneisok: false,
      acvred: null,
      phone: "",
      amount: "",
      key: "",
      remark: "",
      timeshow: false,
      payType: 2,
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(2030, 10, 1),
      status: null, //工单计收还是预付款
      yfkisok: false,
      acv: 0,
      form: {},
      acvs: "代收款",
      show: false,
      val: ["", "", "", "", "", "", "", ""],
      value: "",
      value2: "",
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")) || {},
      user: JSON.parse(localStorage.getItem("userInfo")),
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false,
      isiOS:false,
      currentDate: new Date(),
      date: moment().format("YYYY-MM"),
    };
  },
  watch: {},
  methods: {
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    //根据车牌查询未结算信息
    tourl(item) {
      this.$router.push({
        path: "offer_details",
        query: {
          id: item.id,
          gid:this.gid
        },
      });
    },
    settiem(e) {
      this.date = moment(e).format("YYYY-MM");
      console.log(moment(e).format("YYYY-MM"));
      this.page = 0;
      this.list = [];
      this.getlist();
      this.timeshow = false;
    },
    tourls() {
      this.$router.push({
        path: "/offer",
        query: {
          gid: this.gid,
        },
      });
    },
    //删除
    dlt(item, idx) {
      this.$dialog
        .confirm({
          title: "提示",
          message: "是否确认删除该报价单？",
        })
        .then(() => {
          dltquote({ id: item.id }).then((res) => {
            if (res.code == 200) {
              this.list.splice(idx, 1);
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    //点击车辆结算
    setpay(item, idx) {
      var _this = this;
      this.status = item.status;
      if (item.status >= 4) {
        const data = {
          billNumber: item.billNumber,
          oid: item.id,
          payType: _this.payType,
          totalMoney: item.amountReceivable,
          gid: item.gid,
          type: 1,
        };
        fixpay(data).then((e) => {
          if (e.code == 200) {
            if (e.data.success == "true") {
              window.location.href = e.data.qrurl;
            } else {
              this.$toast(e.data.msg);
            }
          }
        });
      } else {
        this.acvred = idx;
        this.amount = item.amountReceivable;
        this.form = item;
        this.yfkisok = true;
      }
    },
    getjp(val) {
      var _this = this;
      console.log(_this.value2);
      _this.value2 = "sadas";
      this.getcarNo();
      // console.log(val);
      // this.addForm.carNo = val;
      // this.pleshow = false;
    },
    onSearch() {
      console.log("第一种调用方式");
      this.page = 0;
      this.list = [];
      this.getlist();
    },
    getlist() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      var data = {
        gid: this.gid,
        key: this.key,
        time: this.date,
        page: this.page,
        size: this.size,
      };
      getquote(data).then((e) => {
        loading.clear();
        console.log(e);
        if (e.code == 200) {
          let rows = e.data.content;
          this.loading = false;
          this.total = e.data.totalElements;
          if (rows == null || rows.length === 0) {
            // 加载结束
            this.finished = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);
          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= this.total) {
            this.finished = true;
          }
        }
      });
    },
    onLoads() {
      this.page++;
      this.getlist();
    },
    //订单支付
    pay(item) {
      var item = this.form;
      var _this = this;
      var data = {
        carNo: item.carNo,
        gid: item.gid,
        orderId: item.id,
        ordersn: item.billNumber,
        payType: _this.payType,
        price: this.amount,
        type: "99",
      };
      postcbbpay(data).then((res) => {
        console.log(res);
        if (res.data.success == "true") {
          window.location.href = res.data.qrurl;
        } else {
          _this.$toast("调起支付失败");
        }
      });
    },
    //切换

    //其它支付
    paytow() {
      var data = {
        gid: this.gid,
        payType: this.payType,
        price: this.amount2,
        carNo: this.acvs,
        key: "orderOtherPay",
        type: 107,
        remark: this.acvs == "其他" ? this.remark : "",
      };
      postcbbpay(data).then((res) => {
        console.log(res);
        if (res.data.success == "true") {
          window.location.href = res.data.qrurl;
        } else {
          this.$toast("调起支付失败");
        }
      });
    },
    back() {
      window.androidjs.onCallbackIntent("MainActivity");
    },
    setupWebViewJavascriptBridge(callback) {
      if (window.WebViewJavascriptBridge) {
        return callback(window.WebViewJavascriptBridge);
      }
      if (window.WVJBCallbacks) {
        return window.WVJBCallbacks.push(callback);
      }
      window.WVJBCallbacks = [callback];
      let WVJBIframe = document.createElement("iframe");
      WVJBIframe.style.display = "none";
      WVJBIframe.src = "https://__bridge_loaded__";
      document.documentElement.appendChild(WVJBIframe);
      setTimeout(() => {
        document.documentElement.removeChild(WVJBIframe);
      }, 0);
    },
    //
    appSource() {
      var _this = this;
      const u = navigator.userAgent,
        app = navigator.appVersion;
      this.isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (this.isiOS) {
        this.isok = false;
        this.setupWebViewJavascriptBridge((bridge) => {
          var token = bridge.callHandler(
            "getToken",
            null,
            function responseCallback(responseData) {
              localStorage.setItem("Authorization", responseData);
              _this.token = responseData;
              // alert('走了这里' +  _this.token)
              _this.getlist();

              return responseData;
            }
          );
        });
        console.log("ios");
      } else {
        this.isok = true;
        _this.token = window.androidjs.getLocalData("getToken");
        localStorage.setItem("Authorization", _this.token);
        // alert(_this.token)
        _this.getlist();
        console.log("andriod");
      }
    },
  },
  created() {
    // this.getlist();
    //判断是否在微信环境
    this.appSource();
    return;
    if (this.$route.query.gid) {
      sessionStorage.setItem("gid", this.$route.query.gid);
    }
    userModel
      .getGarageInfo({ gid: this.$route.query.gid })
      .then((e) => {
        // loading.clear();

        this.shangjia = e.data;
        sessionStorage.setItem("appid", e.data.idd);
        sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
        if (localStorage.getItem("phone")) {
          this.phone = localStorage.getItem("phone");
          this.setphone();
          this.phoneisok = false;
        } else {
          this.phoneisok = true;
        }
        // return;
        // if (this.isWeixin()) {
        //   var url =
        //     "https://shopping.car-posthouse.cn/ycjpay?gid=" +
        //     this.$route.query.gid;
        //   // 正式
        //   const redirect_uri = encodeURIComponent(url);
        //   window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
        // }
      })
      .catch(() => loading.clear());
    // return
    // if (this.$route.query.code == undefined) {
    //   console.log(this.$route.query.code);
    //   var url = this.$route.path + "?gid=" + this.$route.query.gid;
    //   localStorage.setItem("beforeUrl", url);

    //   return;
    // } else {
    //   wxLogin({
    //     code: this.$route.query.code,
    //     gid: sessionStorage.getItem("gid"),
    //   }).then((e) => {
    //     if (e.code == 200) {
    //       localStorage.setItem("Authorization", e.data.authorization);
    //       localStorage.setItem("openId", e.data.openId);
    //       console.log(e);
    //     }
    //   });
    // }
  },
  mounted() {},
  computed: {},
};
</script>
  <style>
body {
  background: #f6f6f6;
}
</style>
<style lang="less" scoped>
.nav-bar {
  position: sticky;
  top: 46px;
  left: 0;
  width: 100%;
}
.carNo {
  font-weight: 400;
  color: #fff;
  font-family: JDZhengHT-Regular;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  height: 26px;
  width: 80px;
  background: url(../../../assets/img/chepaibj.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flx {
  display: flex;
  align-items: center;
}
.time {
  padding: 6px 12px;
  font-size: 16px;
  font-weight: bold;
}
.mian {
  // margin: 0 12px;
  border-top: 1px solid #f5f5f5;
  border-radius: 6px;
  padding: 10px 0;
  background: #fff;
  display: flex;
  align-items: center;
  .sc {
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .zuolit {
    flex: 1;
    border-right: 1px dashed #dcdcdc;
  }
  .xmdiv {
    line-height: 30px;

    font-size: 14px;
    padding: 0 12px;
  }
  .ris {
    text-align: right;
  }
  .tjdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    // height: 40px;
    background: #fff;
    // line-height: 40px;
    padding: 10px 0;
    border-top: 1px solid #f4f4f4;
  }
}
.bot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  .van-button {
    width: 94%;
    margin: auto;
    display: block;
    font-size: 15px;
    height: 42px;
  }
}
</style>

  
  